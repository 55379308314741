import * as React from "react";
import Header from "../components/sections/Header";
import JoinSection from "../components/sections/JoinSection";
import Footer from "../components/sections/Footer";
import JoinItem from "../components/elements/JoinItem";
import PageTitle from "../components/elements/PageTitle";

const JoinPage = () => (
  <div className="bg-white">
    <PageTitle
      pageTitle={"Join our Team"}
      description={`IT Grows is always open for new opportunities and is glad to provide them. Try your skills here!`}
    />
    <Header />
    <JoinSection
      title="We are hiring"
      // description="We are searching professionals into our team"
    >
      <JoinItem
        categoryName={"JavaScript 19864"}
        title={"JavaScript Developer"}
        articleTitle={`JavaScript19864`}
        showMoreText={"Show more"}
        hideMoreText={"Show less"}
      />
      <JoinItem
        categoryName={"Java19867"}
        title={"Java developer"}
        articleTitle={`Java19867`}
        showMoreText={"Show more"}
        hideMoreText={"Show less"}
      />
      <JoinItem
        categoryName={"Java19868"}
        title={"Java developer"}
        articleTitle={`Java19868`}
        showMoreText={"Show more"}
        hideMoreText={"Show less"}
      />
      <JoinItem
        categoryName={"Java19869"}
        title={"Java developer"}
        articleTitle={`Java19869`}
        showMoreText={"Show more"}
        hideMoreText={"Show less"}
      />
      <JoinItem
        categoryName={"JavaScript Angular 19870"}
        title={"JavaScript Angular developer"}
        articleTitle={`JavaScript19870`}
        showMoreText={"Show more"}
        hideMoreText={"Show less"}
      />
    </JoinSection>
    <Footer />
  </div>
);

export default JoinPage;
