import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

const JoinItem = ({
  categoryName,
  title,
  articleTitle,
  showMoreText,
  hideMoreText,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [markdown, setMarkdown] = useState({});

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            title
          }
          excerpt(format: MARKDOWN, pruneLength: 2147483647)
        }
      }
    }
  `);

  useEffect(() => {
    setMarkdown(
      data.allMarkdownRemark.nodes.find((e) =>
        e.frontmatter.title.includes(articleTitle)
      )
    );
  }, [data]);

  return (
    <div className="mt-8 px-4 pb-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
      <div className="max-w-max lg:max-w-7xl mx-auto border-t border-gray-200 pt-8">
        <div className="relative z-10 mb-8 md:mb-2 md:px-6">
          <div className="text-base max-w-prose lg:max-w-none">
            <h3 className="text-base font-semibold text-amber-600 tracking-wide uppercase">
              {categoryName}
            </h3>
            <h2 className="text-3xl font-extrabold text-blue-gray-900 tracking-tight sm:text-4xl">
              {title}
            </h2>
          </div>
        </div>
        <div className="relative">
          <svg
            className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
            />
          </svg>
          <svg
            className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
            />
          </svg>
          <div className="relative md:bg-white md:px-6">
            <div
              className={`text-blue-gray-900 ${
                showMore ? "vacancy sm:columns-2 gap-x-[4%]" : "vacancy_short"
              }`}
            >
              {markdown.excerpt && (
                <ReactMarkdown>
                  {showMore
                    ? markdown.excerpt
                    : `${markdown.excerpt.substring(0, 550)}...`}
                </ReactMarkdown>
              )}
            </div>
            <div className="mt-8 inline-flex rounded-md shadow">
              {showMore ? (
                <button
                  onClick={() => setShowMore(!showMore)}
                  className="cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-600"
                >
                  {hideMoreText}
                  <ChevronUpIcon
                    className="flex-shrink-0 w-6 h-6 text-amber-300 pl-2"
                    aria-hidden="true"
                  />
                </button>
              ) : (
                <button
                  onClick={() => setShowMore(!showMore)}
                  className="cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-600"
                >
                  {showMoreText}
                  <ChevronDownIcon
                    className="flex-shrink-0 w-6 h-6 text-amber-300 pl-2"
                    aria-hidden="true"
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinItem;
