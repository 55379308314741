import * as React from "react";
import Title from "../elements/Title";

const JoinSection = ({ title, description, children }) => (
  <div className="bg-white">
    <Title title={title} description={description} />
    {children}
  </div>
);

export default JoinSection;
