import * as React from "react";

const Title = ({ title, description }) => (
  <div className="pb-16 pt-12 bg-blue-gray-900 sm:pb-20 sm:pt-16">
    <div className="max-w-md mx-auto px-4 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8">
      {/* Title */}
      <h1 className="text-4xl leading-10 font-extrabold tracking-tight text-white text-center sm:text-5xl sm:leading-none lg:text-6xl">
        {title}
      </h1>
      {/* Description */}
      {description ? (
        <p className="mt-6 max-w-3xl mx-auto text-xl leading-normal text-white/80 text-center">
          {description}
        </p>
      ) : (
        ""
      )}
    </div>
  </div>
);

export default Title;
